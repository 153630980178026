<div style="margin-left: 40px; margin-right: 40px;">
    <button mat-button [routerLink]="['/']"><mat-icon>arrow_back</mat-icon>Back to review</button>
    <h2>Settings</h2>
    <h3>Filters</h3>
    <p>Apply filters below to include only these items in your review queue. Leaving filters empty includes all pending
        review items.</p>
    <p><b>Note:</b> Filters are cleared on page refresh.</p>
    <div style="display: flex; flex-direction: column; gap: 16px; align-items: start;">
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Sellers</mat-label>
            <mat-chip-grid #sellerChipGrip>
                @for (sellerProfileId of sellerProfileIds.value; track sellerProfileId) {
                    <mat-chip-row (removed)="removeSellerFilter(sellerProfileId)" [editable]="false">
                        {{ getSellerById(sellerProfileId)?.displayName ?? '(unknown)'}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input placeholder="Search sellers..."
                    [formControl]="sellerProfileFilterControl" [matChipInputFor]="sellerChipGrip" [matAutocomplete]="auto" #sellerProfileInput />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSellerFilter($event)">
                    @for (sellerProfile of filteredSellerProfiles | async; track sellerProfile.id) {
                    <mat-option [value]="sellerProfile.id">{{sellerProfile.displayName}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-chip-grid>
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Models</mat-label>
            <mat-chip-grid #modelChipGrid>
                @for (model of models.value; track model.id) {
                    <mat-chip-row (removed)="removeModelFilter(model)" [editable]="false">
                        {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input matInput placeholder="Search models..." [formControl]="modelFilterControl" [matChipInputFor]="modelChipGrid" [matAutocomplete]="modelFilterAutocomplete" #modelInput>
                <mat-autocomplete #modelFilterAutocomplete="matAutocomplete" (optionSelected)="addModelFilter($event)">
                    @for(model of filteredModels | async; track model.id) {
                      <mat-option [value]="model">
                          <div style="display: flex; align-items: center;">
                            @if(model.images?.[0]?.url) {
                              <img [src]="model.images?.[0]?.url" class="image" style="max-height: 40px;">
                            }
                            <span class="title">
                              {{model.brand.displayName ?? model.brand.name}} {{model.displayName ?? model.name}} {{model.referenceNumber}}
                            </span>
                          </div>
                      </mat-option>
                    }
                  </mat-autocomplete>
            </mat-chip-grid>
          </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Conditions</mat-label>
            <mat-chip-grid #conditionFilterChipGrid>
                @for (condition of conditions.value; track condition) {
                    <mat-chip-row (removed)="removeCondition(condition)" [editable]="false">
                        @if (condition === 'UNWORN') {
                            Unworn
                        } @else {
                            Pre-Owned
                        }
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                    }
                <input [matChipInputFor]="conditionFilterChipGrid" [matAutocomplete]="conditionAuto" />
                <mat-autocomplete #conditionAuto="matAutocomplete" (optionSelected)="addCondition($event)">
                    <mat-option [value]="'UNWORN'">Unworn</mat-option>
                    <mat-option [value]="'PREOWNED'">Pre-Owned</mat-option>
                </mat-autocomplete>
            </mat-chip-grid>
          </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Pricing Model</mat-label>
            <mat-chip-grid #typeFilterChipGrid>
                @if (activePricingModel.value === 'FIXED_PRICE') {
                    <mat-chip-row (removed)="activePricingModel.setValue(null)" [editable]="false">
                        Fixed Price
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                } @else if (activePricingModel.value === 'AUCTION') {
                    <mat-chip-row (removed)="activePricingModel.setValue(null)" [editable]="false">
                        Auction
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input [matChipInputFor]="typeFilterChipGrid" [matAutocomplete]="typeAuto" [formControl]="activePricingModel" />
                <mat-autocomplete autoActiveFirstOption #typeAuto="matAutocomplete">
                    <mat-option [value]="'FIXED_PRICE'">Fixed Price</mat-option>
                    <mat-option [value]="'AUCTION'">Auction</mat-option>
                </mat-autocomplete>
            </mat-chip-grid>
          </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Flagged</mat-label>
            <mat-chip-grid #flaggedFilterChipGrid>
                @if (flagged.value === true) {
                    <mat-chip-row (removed)="flagged.setValue(null)" [editable]="false">
                        Flagged
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                } @else if (flagged.value === false) {
                    <mat-chip-row (removed)="flagged.setValue(null)" [editable]="false">
                        Not Flagged
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input [matChipInputFor]="flaggedFilterChipGrid" [matAutocomplete]="flaggedAuto" [formControl]="flagged" />
                <mat-autocomplete autoActiveFirstOption #flaggedAuto="matAutocomplete">
                    <mat-option [value]="true">Flagged</mat-option>
                    <mat-option [value]="false">Not Flagged</mat-option>
                </mat-autocomplete>
            </mat-chip-grid>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Tags</mat-label>
            <mat-chip-grid #tagsFilterChipGrid>
                @for (tagId of tagIds.value; track tagId) {
                    <mat-chip-row (removed)="removeTag(tagId)" [editable]="false">
                        {{getTagById(tagId)?.displayValue}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input [matChipInputFor]="tagsFilterChipGrid" [matAutocomplete]="tagsAuto" />
                <mat-autocomplete #tagsAuto="matAutocomplete" (optionSelected)="addTag($event)">
                    @for (tag of tags; track tag.id) {
                        <mat-option [value]="tag.id">{{tag.displayValue}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-chip-grid>
          </mat-form-field>
        <button mat-stroked-button color="warn" (click)="resetFiltersButtonPressed()">Clear Filters</button>
        <div style="width: 100%; text-align: center; font-size: 10pt; opacity: 0.2;">
            {{environment.version}}
        </div>
    </div>
</div>