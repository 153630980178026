export const environment = {
  production: false,
  apiUrl: "https://api.sandbox.bezel.cloud",
  authClientId: "r6a0Wn8nXDE7HBha3RW9Fj8WnLflYiJp",
  authDomain: "identity.sandbox.bezel.cloud",
  bezelDomain: "https://shop.sandbox.bezel.cloud",
  consoleDomain: "https://console.sandbox.bezel.cloud",
  version: 'main-cf2acf0',
  platformCoreUuid: "1CMOzHEkzgzOqzzPIkiYR9I0oJGJg9Yl@clients",
  dolphinUuid: "WlbS5zxQqz677pvjPYlbL9M2nibKOZNG@clients"
};
