import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import Listing from '../models/listing.model';
import ListingStatusChangeEvent from '../models/listing-status-change-event.model';

export interface ListingsQuery {
  start?: number;
  limit?: number;
  status?: string;
  models?: number[];
  sellerProfileFilter?: number[];
  conditions?: string[];
  inventoryNumbers?: string[];
  auctionInfo?:{
    live?: boolean;
    ended?: boolean;
  };
  flagged?: boolean;
  tags?: number[];
  activePricingModel?: string;
  sortKey?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ListingsService {
  constructor(private http: HttpClient) { }
  
  public getListings(query: ListingsQuery): Observable<any> {
    var start = query.start ? query.start : 0;
    var limit = query.limit ? query.limit : 20;
    var path = `/marketplace/listings?start=${start}&limit=${limit}&expand[]=sellerProfile&expand[]=model&expand[]=listingChallenge&expand[]=sellerProfile.payoutAccount&expand[]=sellerProfile.user&expand[]=issues&expand[]=accessories&expand[]=auctionInfo.guarantorBuyerProfile.user`;
    if (query?.status) {
      path += `&statuses=${query.status}`;
    }
    if (query?.models) {
      path += `&models=${query.models.join(',')}`
    }
    if (query?.sellerProfileFilter) {
      for (var sellerProfileId of query?.sellerProfileFilter) {
        path += `&sellerProfile[]=${sellerProfileId}`
      }
    }
    if (query?.conditions) {
      for (var condition of query?.conditions) {
        path += `&condition[]=${condition}`
      }
    }
    if (query?.flagged != null) {
      path += `&flagged=${query.flagged}`;
    }
    if (query?.tags) {
      for (var tagId of query.tags) {
        path += `&tags[]=${tagId}`
      }
    }
    if (query?.activePricingModel) {
      path += `&activePricingModel=${query.activePricingModel}`
    }
    if (query.auctionInfo?.live != undefined) {
      path += `&auctionInfo.live=${query.auctionInfo.live}`
    }
    if (query.auctionInfo?.ended != undefined) {
      path += `&auctionInfo.ended=${query.auctionInfo.ended}`
    }
    if (query?.sortKey) {
      path += `&sort=${query.sortKey}`
    }
    if (query?.inventoryNumbers) {
      query.inventoryNumbers.forEach(inventoryNumber => path += `&inventoryNumber[]=${inventoryNumber}`)
    }
    return this.http.get<Listing[]>(environment.apiUrl + path, {observe: 'response'}).pipe(
      map(response => {
        return {
          data: response.body!.map(l => Object.assign(new Listing(), l)),
          totalCount:  response.headers.get('X-Total-Count')
        }
      })
    );
  }
    
  public getListing(listingId: number): Observable<Listing> {
    return this.http.get<Listing>(environment.apiUrl + `/marketplace/listings/${listingId}?expand[]=sellerProfile&expand[]=model&expand[]=listingChallenge&expand[]=sellerProfile.payoutAccount&expand[]=sellerProfile.user&expand[]=issues&expand[]=accessories&expand[]=auctionInfo.guarantorBuyerProfile.user`).pipe(
      map(listing => Object.assign(new Listing(), listing))
    );
  }

  public getListingStatusChangeEvents(listingId: number): Observable<ListingStatusChangeEvent[]> {
    return this.http.get<ListingStatusChangeEvent[]>(environment.apiUrl + `/marketplace/listings/${listingId}/status-change-events?expand[]=user`);
  }

  public approveListing(listingId: number, rationale: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/publish`, { rationale });
  }
  
  public rejectListing(listingId: number, rationale: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/reject`, { rationale });
  }

  public archiveListing(listingId: number, rationale?: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/archive`, { rationale });
  }

  public unarchiveListing(listingId: number, rationale?: string) {
    return this.http.post(environment.apiUrl + `/marketplace/listings/${listingId}/unarchive`, { rationale });
  }

  public createListing(params: any) {
    return this.http.post(environment.apiUrl + `/marketplace/listings`, params);
  }

  public updateListing(listingId: number, params: any) {
    return this.http.patch(environment.apiUrl + `/marketplace/listings/${listingId}`, params)
  }
}
